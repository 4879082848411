.popover-wrapper {
  $arrow-size: 1.125rem;
  $arrow-horizontal: 0.875rem;
  $arrow-vertical: 0.5625rem;
  position: relative;

  .popover-trigger {
    cursor: pointer;
  }

  .popover {
    border: none;
    padding-top: 0 !important;

    .transition-container {
      display: flex;
      width: 100%;
      .arrow-container {
        width: inherit;
        height: inherit;
        position: relative;

        &:before {
          content: '';
          width: $arrow-size;
          height: $arrow-size;
          display: block;
          background: white;
          border: 1px solid #eff1f5;
          position: absolute;
          z-index: 2;
          border-radius: 0.1875rem 0 0 0;
          transform: rotate(45deg);
          clip-path: polygon(100% 0, 0 0, 0 100%);
          pointer-events: none;
        }
      }

      &[class^='top'] {
        flex-direction: column-reverse;

        .arrow-container {
          height: $arrow-size;
          margin-top: -$arrow-vertical;

          &::before {
            transform: rotate(225deg);
          }
        }

        &[class*='-start'] {
          .arrow-container::before {
            left: $arrow-horizontal;
          }
        }
        &[class*='-center'] {
          .arrow-container::before {
            left: 50%;
            transform: translateX(-50%) rotate(225deg);
          }
        }
        &[class*='-end'] {
          .arrow-container::before {
            right: $arrow-horizontal;
          }
        }
      }

      &[class^='right'] {
        flex-direction: row;

        .arrow-container {
          width: $arrow-size;
          margin-right: calc($arrow-horizontal / -2);

          &::before {
            transform: rotate(-45deg);
          }
        }

        &[class*='-start'] {
          .arrow-container::before {
            top: $arrow-horizontal;
          }
        }
        &[class*='-center'] {
          .arrow-container::before {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
        &[class*='-end'] {
          .arrow-container::before {
            bottom: $arrow-horizontal;
          }
        }
      }

      &[class^='bottom'] {
        flex-direction: column;

        .arrow-container {
          height: $arrow-size;
          margin-bottom: -$arrow-vertical;
        }

        &[class*='-start'] {
          .arrow-container::before {
            left: $arrow-horizontal;
          }
        }
        &[class*='-center'] {
          .arrow-container::before {
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
          }
        }
        &[class*='-end'] {
          .arrow-container::before {
            right: $arrow-horizontal;
          }
        }
      }

      &[class^='left'] {
        flex-direction: row-reverse;

        .arrow-container {
          width: $arrow-size;
          margin-left: calc($arrow-horizontal / -2);

          &::before {
            transform: rotate(135deg);
          }
        }

        &[class*='-start'] {
          .arrow-container::before {
            top: $arrow-horizontal;
          }
        }
        &[class*='-center'] {
          .arrow-container::before {
            top: 50%;
            transform: translateY(-50%) rotate(135deg);
          }
        }
        &[class*='-end'] {
          .arrow-container::before {
            bottom: $arrow-horizontal;
          }
        }
      }
    }
  }
}
