@import '@/assets/scss/constants.scss';
@import '@/assets/scss/mixin.scss';

.terms-modal {
  & > div {
    max-height: rm(712);
  }
  .tab {
    width: 30%;
    float: left;
    height: rm(590);
    overflow-x: auto;
    padding-right: rm(10);
    border-right: rm(1) solid $grey;

    button {
      width: 100%;
      border: none;
      color: $black;
      outline: none;
      display: flex;
      color: $green;
      height: rm(60);
      cursor: pointer;
      text-align: left;
      transition: 0.3s;
      font-size: rm(20);
      align-items: center;
      margin-bottom: rm(10);
      border-radius: rm(12);
      background-color: $grey;
      justify-content: space-between;
      padding: rm(18) rm(18) rm(18) rm(30);
      &:hover {
        background-color: #ddd;
        & span[class^='ic-'],
        & span[class*=' ic-'] {
          &::before {
            opacity: 0.8;
          }
        }
      }
      & span[class^='ic-'],
      & span[class*=' ic-'] {
        width: rm(24);
        height: rm(24);
        display: flex;
        align-self: center;
        justify-content: center;
      }

      &.active {
        color: $white;
        background-color: $green;
        span[class^='ic-'],
        span[class*=' ic-'] {
          &::before {
            color: $white;
          }
        }
      }
    }
  }

  .tab-content {
    float: left;
    width: 70%;
    height: rm(590);
    padding: 0 rm(30);
    border-left: none;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: rm(10);
      height: rm(120);
      border-radius: rm(10);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: rm(10);
      background-color: $green;
    }
  }

  .print-btn {
    border: none;
    display: flex;
    color: $green;
    cursor: pointer;
    font-weight: 400;
    font-size: rm(14);
    font-style: normal;
    align-items: center;
    border-radius: rm(30);
    padding: rm(5) rm(20);
    border: rm(1) solid $green;
    background-color: transparent;

    img {
      margin-right: rm(10);
    }
  }

  .tab-title {
    color: $black;
    font-size: rm(20);
    text-transform: uppercase;
  }

  .tab-text {
    color: #5e5e5e;
    font-size: rm(13);
    margin-bottom: rm(38);
  }
}
