@import '@/assets/scss/constants.scss';
@import '@/assets/scss/mixin.scss';

.loader-box {
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
  height: 100%;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: $green;

  &.hideContent {
    opacity: 1;
  }

  .loader {
    z-index: 1999;
    width: rm(100);
    height: rm(100);
    position: relative;
    display: inline-block;
  }

  .loader::after,
  .loader::before {
    content: '';
    top: 0;
    left: 0;
    width: rm(100);
    height: rm(100);
    border-radius: 50%;
    position: absolute;
    box-sizing: border-box;
    border: rm(2) solid $white;
    animation: loader 2s linear infinite;
  }

  .loader::after {
    animation-delay: 1s;
  }

  @keyframes loader {
    0% {
      transform: scale(0);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
