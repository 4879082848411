.skeleton-container {
  background: #f9fafb;
  background: linear-gradient(110deg, #006639 8%, #05a25e 18%, #006639 33%);
  border-radius: 5px;
  background-size: 200% 200% !important;
  animation: 1.5s shine linear infinite !important;
  background-position-y: 0px !important;

  &.default {
    background: linear-gradient(110deg, #169a5a 8%, #42e094 18%, #169a5a 33%);
  }

  &.inline {
    display: inline-block;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
